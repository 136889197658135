.rnc {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 370px;
    /* height: 70%; */
    max-height: 100px;
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    padding-bottom: 0px;
  }
  
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 0px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 10px;
  }
  
  .rnc-canvas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    width: 70%;
    height: 70%;
    order: 1;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 20px;
    height: 13px;
    box-sizing: border-box;
    border-radius: 4px;
    order: 2;
  }
  
  .rnc-button svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 6px 6px;
    font-size: inherit;
    font-family: inherit;
    border-radius: 4px;
    width: 55%;
    height: 26px;
    order: -1;
  }
  