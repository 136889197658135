.container-bg{
    height: 100vh;
    background-image: url("../../assets/images/login-bg-v2.png") !important;    background-size: cover !important;background-repeat: no-repeat !important;background-position: right !important;
}
/* .login_wrapper{
    position: absolute;
    top: 46%;
    left: -30%;
    transform: translate(-250%, -50%);
    width: 125px;
    height: 500px;
} */
/* .login_content form{
    background-color: rgba(0, 0, 0, 0.8);
    padding: 18%;
    width: 400px;
} */
.app-logo{
    /* padding: ; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-body{
    margin: 0 auto;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.loginForm{
    /* width:75%; */
}
.form-control-user{
    border-radius: 40px;
    /* padding: 6% 4%; */
    background-color: #EBEEFD !important;
    color: #323232 !important;
    box-shadow: 0px 7px 15px 0px #c8d0f7;
    padding: 10px 10px 10px 40px; /* Add padding to make room for icon */
    /* background-image: url('../../assets/images/login_email.png'); Specify the path to your icon */
    /* background-size: 20px 20px; Set the size of your icon */
    /* background-repeat: no-repeat; Prevent the icon from repeating */
    /* background-position: left 10px center; Position the icon to the right of the input box */
    border: none; /* Add a border for visual clarity */
    border-radius: 5px; /* Round the corners for a nicer look */
    height: 44px;
}
.form-control-password{
    border-radius: 40px;
    /* padding: 6% 4%; */
    background-color: #EBEEFD !important;
    color: #323232 !important;
    box-shadow: 0px 7px 15px 0px #c8d0f7;
    padding: 10px 10px 10px 40px; /* Add padding to make room for icon */
    /* background-image: url('../../assets/images/login_password.png'); Specify the path to your icon */
    /* background-size: 20px 20px; Set the size of your icon */
    /* background-repeat: no-repeat; Prevent the icon from repeating */
    /* background-position: left 10px center; Position the icon to the right of the input box */
    border: none; /* Add a border for visual clarity */
    border-radius: 5px; /* Round the corners for a nicer look */
    height: 44px;
}
input:-internal-autofill-selected, input:-webkit-autofill {
    background-color: #EBEEFD !important;
    color: #323232 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    background-color: #EBEEFD !important;
    color: #323232 !important;
}

input:-ms-input-placeholder {
    background-color: #EBEEFD !important;
    color: #323232 !important;
}

input::-ms-input-placeholder {
    background-color: #EBEEFD !important;
    color: #323232 !important;
}
input::placeholder {
    color: #323232 !important;
}
.loginForm h4{
    /* display: none; */
    text-align: center;
    color: #323232;
    font-weight: 700;
}
.btn-user{
    background-color: #6263FB;
    color: white;
    font-size: 16px;
    width: 40%;
    margin-top: 4%;
    border-radius: 5px;
    border: 2px solid #6263FB;
    box-shadow: 0px 7px 29px 0px #5f79f7;
}
.btn-user:hover, .btn-user:not(:disabled):not(.disabled).active:focus, .btn-user:not(:disabled):not(.disabled):active:focus, .show>.btn-user.dropdown-toggle:focus {
    color: #6263FB;
    background-color: #EBEEFD;
    border: 2px solid #6263FB;
    font-weight: 500;
    outline: none;
}